<template>
    <div class="content-full">
        <div class="row">
            <div class="col-md-4 d-flex">
                <div class="block w-100" style="box-shadow: 0 8px 20px rgba(0,0,0,.1)">
                    <div class="block-content block-content-full">
                        <div class="attestation-block">
                            <test-state-widget :key="`test_state_${testing_on}`"></test-state-widget>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex">
                <div class="block w-100" style="box-shadow: 0 8px 20px rgba(0,0,0,.1)">
                    <div class="block-content block-content-full h-100">
                        <test-control-widget
                            :testing_on="testing_on"
                            :testing_date="testing_date"
                            @testing_on="testing_on=!testing_on"></test-control-widget>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex">
                <div class="block w-100" style="box-shadow: 0 8px 20px rgba(0,0,0,.1)">
                    <div class="block-content block-content-full h-100">
                        <test-repeat-widget :testing_on="testing_on" @testing_on="testing_on=false"></test-repeat-widget>
                    </div>
                </div>
            </div>
        </div>
        <questions-block :structureList="structureList" :materialsExists="materialsExists"></questions-block>
    </div>
</template>

<script>
    import session from '@/api/session';

    export default {
        name: "Settings",
        components: {
            TestStateWidget : () => import('../widgets/TestStateWidget'),
            TestRepeatWidget : () => import('../widgets/TestRepeatWidget'),
            TestControlWidget : () => import('../widgets/TestControlWidget'),
            QuestionsBlock : () => import('./QuestionsBlock')
        },
        data() {
            return {
                structureList: [],
                materialsExists: false,
                testing_on: false,
                testing_date: null
            }
        },
        async created() {
            try {
                const request = session.get('/api/v1/testing-settings/initialize/');
                const response = await request;
                this.testing_on = response.data.testing_on;
                this.testing_date = response.data.testing_date;
                this.structureList = response.data.structure_list;
                this.materialsExists = response.data.materials_exists;
            } catch (error) {
                console.error(error)
            }
        }
    }
</script>

<template>
    <content-view>
        <settings/>
    </content-view>
</template>

<script>
import ContentView from '@/views/menu/ContentView'
import Settings from '@/components/test/settings/Settings'
import { metaTitle } from '@/mixins/meta_title'
import session from "@/api/session";

export default {
    name: 'TestingSettings',
    mixins: [metaTitle],
    components: { Settings, ContentView },
    async beforeCreate () {
        try {
            const request = session.get('/api/v1/testing-settings/access_update/');
            const response = await request;
        } catch (error) {
            this.$router.push('/403');
        }
    }
}
</script>

<style scoped>

</style>
